import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const CoachImage = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 4rem;
`;

const coaches = ({ data }) => {
  console.log(data);
  const theCoaches = data.coaches.nodes;
  return (
    <div>
      <h1>Coaches</h1>
      <ul>
        {theCoaches.map((coach) => (
          <>
            <li>{coach.name}</li>
            <CoachImage>
              <Img fluid={coach.image.asset.fluid} alt={coach.name} />
            </CoachImage>
          </>
        ))}
      </ul>
    </div>
  );
};

export default coaches;

export const query = graphql`
  query CoachesQuery {
    coaches: allSanityCoach {
      nodes {
        name
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
